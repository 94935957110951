import { Grid, Paper, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../components';
import DevicesComponent from '../reservasEspeciales/components/Devices';

const PanelReservasView = () => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();

  // const entidadesStore = Redux.useSelector((state) => state.entidades);
  // const areasStore = Redux.useSelector((state) => state.areas);

  React.useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [openModalForm, setOpenModalForm] = React.useState(false);

  const tabPanel = (
    <Paper elevation={2}>
      <Grid container>
        <Grid item xs={12} md={12}></Grid>
      </Grid>
    </Paper>
  );

  return (
    <Grid container spacing={2} sx={{ marginTop: '1px' }}>
      <Grid item md={4}>
        <DevicesComponent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            {['10:00', '11:00', '12:00', '13:00', '14:00', '15:00'].map(
              (item, index) => (
                <div
                  key={index}
                  style={{
                    width: '20%',
                    backgroundColor: 'red',
                    // padding: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '5px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                  }}
                >
                  {item}
                </div>
              )
            )}
          </div>
        </DevicesComponent>
      </Grid>
      <Grid item md={8}>
        <Paper elevation={2} sx={{ p: 2 }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Typography variant="h5" color="initial">
                Nuevas reservas
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Modal
        id="modalFormAreas"
        title="Nueva reserva"
        open={openModalForm}
        onClose={() => setOpenModalForm(!openModalForm)}
      ></Modal>
    </Grid>
  );
};

export default PanelReservasView;
