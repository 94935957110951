import * as React from 'react';
import * as Redux from 'react-redux';
import {
  Button,
  Grid,
  LinearProgress,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import * as CONST from '../../../constants/FieldsConst';
import Box from '@mui/material/Box';
import {
  saveReservasEspecialesAction,
  getAllAreasAction,
  getAllSubAreasAction,
  setActivoSubAreasAction,
  setActivoAreasAction,
} from '../../../store/Actions';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import { InputLabel } from '@mui/material';
import { TextField } from '@mui/material';
import { FechaField } from '../../usuarios/components/FieldsUsuarios';
import { ButtonGroup } from '@mui/material';
import { Switch } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { FormHelperText } from '@mui/material';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { BLANCO_COLOR, AZUL_COLOR, MORADO_COLOR } from '../../../constants/ColorsConst';
import { dateformat, getDateTimezone } from '../../../helpers';
import dayjs from 'dayjs';
import LoadingComponent from '../../../components/Loading';
import { useNotification } from '../../../helpers/notification';
import AlertMsgComponent from '../../../components/AlertMsg';
import { Modal } from '../../../components';

const FormReservasEspeciales = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const areasStore = Redux.useSelector((state) => state.areas);
  const subareasStore = Redux.useSelector((state) => state.subareas);
  const reservasEspecialesStore = Redux.useSelector(
    (state) => state.reservasEspeciales
  );

  const timezone = entidadesStore.activo.country.timezone;

  const activo = reservasEspecialesStore.activo;

  const initData = {
    [CONST.DESCRIPTION]: '',
    [CONST.DATE_FROM]: dateformat(null, {}),
    [CONST.ENTRY_TIME]: '07:00',
    [CONST.DEPARTURE_TIME]: '20:00',
    [CONST.DATE_TO]: '',
    months: [],
    subareaIds: [],
    areaIds: [],
  };

  const [reserva, setReserva] = React.useState({
    ...initData,
    // [CONST.AREA_ID]: '',
    // [CONST.SUB_AREA_ID]: '',
  });

  const [modalConfirm, setModalConfirm] = React.useState(false);

  const [dataError, setDataError] = React.useState({
    // [CONST.AREA_ID]: false,
    // [CONST.SUB_AREA_ID]: false,
    areaIds: false,
    subareaIds: false,
    [CONST.DESCRIPTION]: false,
    [CONST.ENTRY_TIME]: false,
    [CONST.DEPARTURE_TIME]: false,
    [CONST.ENTRY_TIME_ERROR]: false,
    [CONST.DEPARTURE_TIME_ERROR]: false,
    [CONST.TIME_ERROR_IGUAl]: false,
  });
  const _handleSetDataFieldReserva = (key, value) =>
    setReserva({ ...reserva, [key]: value });

  React.useEffect(() => {
    const fetchData = async () => {
      if (areasStore.all && areasStore.all.length === 0) {
        await getAllAreasAction(dispatch, {
          entityId: entidadesStore.activo.id,
        });
      }
      setActivoSubAreasAction(dispatch, null);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loadingSubAreas, setLoadingSubAreas] = React.useState(false);

  const subareaOptions = areasStore.all.filter((el) => {
    return reserva.areaIds.includes(el.id);
  }).map((el) => {
    return el.subareas;
  }).flat();

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     if (reserva.areaId !== '') {
  //       const area = areasStore.all.find((el) => el.id === reserva.areaId);
  //       if (area.blockReservation !== 'day') {
  //         setReserva({
  //           ...reserva,
  //           [CONST.ENTRY_TIME]: area.openingTime,
  //           [CONST.DEPARTURE_TIME]: area.closingTime,
  //         });
  //       }
  //       setActivoAreasAction(dispatch, area);
  //       setLoadingSubAreas(true);
  //       await getAllSubAreasAction(dispatch, {
  //         areaId: reserva.areaId,
  //       });
  //       setLoadingSubAreas(false);
  //       setDataError({
  //         ...dataError,
  //         [CONST.ENTRY_TIME_ERROR]: false,
  //         [CONST.DEPARTURE_TIME_ERROR]: false,
  //         [CONST.TIME_ERROR_IGUAl]: false,
  //       });
  //       setIsTDia(area.blockReservation === 'day');
  //     }
  //   };
  //   fetchData();
  // }, [reserva.areaId]); // eslint-disable-line react-hooks/exhaustive-deps

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     if (reserva.subareaId !== '') {
  //       const subareaX = subareasStore.all.find(
  //         (el) => el.id === reserva.subareaId
  //       );
  //       setActivoSubAreasAction(dispatch, subareaX);
  //       if (subareasStore.activo !== null) {
  //         setDataError({
  //           ...dataError,
  //           [CONST.ENTRY_TIME_ERROR]: false,
  //           [CONST.DEPARTURE_TIME_ERROR]: false,
  //           [CONST.TIME_ERROR_IGUAl]: false,
  //         });
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [reserva.subareaId]); // eslint-disable-line react-hooks/exhaustive-deps

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).forEach((key) => {
      const val = reserva[key];
      const valid = val === null || val === '' || val === 0 || (Array.isArray(val) && val.length === 0);
      r[key] = valid;
      va.push(valid);
    });
    setDataError(r);
    return !va.includes(true);
  };

  const [loadingSave, setLoadingSave] = React.useState(false);
  const handleSubmit = async () => {
    const valid = _validDataForm();

    if (tab === 2 && reserva.months.length === 0) {
      addNotification('Falta agregar el mes de la reserva', { warning: true });
    }
    if (tab === 1 && reserva.dateFrom === '') {
      addNotification('Falta agregar el día de la reserva', { warning: true });
    }
    if (valid) {
      const data = {
        reservations:
          tab === 1
            ? [
                {
                  [CONST.DESCRIPTION]: reserva.description,
                  [CONST.ENTRY_TIME]: getDateTimezone(
                    `${reserva.dateFrom} ${reserva.entryTime}`,
                    timezone
                  ),
                  [CONST.DEPARTURE_TIME]: getDateTimezone(
                    `${reserva.dateFrom} ${reserva.departureTime}`,
                    timezone
                  ),
                },
              ]
            : todosDiasMes(),
        // [CONST.SUB_AREA_ID]: reserva.subareaId,
        subareaIds: reserva.subareaIds
      };

      setLoadingSave(true);
      let res = await saveReservasEspecialesAction(
        { dispatch, addNotification },
        data,
        entidadesStore.activo.id,
        {
          areaId: reservasEspecialesStore.dataSearchAreaId,
          date: dayjs(reservasEspecialesStore.dateSearch)
            .startOf('month')
            .toDate(),
          dateTo: dayjs(reservasEspecialesStore.dateSearch)
            .endOf('month')
            .toDate(),
        }
      );
      const area = areasStore.all.find((el) => el.id === reserva.areaId);
      if (res) {
        setReserva({
          ...reserva,
          ...initData,
          // [CONST.ENTRY_TIME]: area.openingTime,
          // [CONST.DEPARTURE_TIME]: area.closingTime,
        });
        setOpenModalForm(false);
      }
      setLoadingSave(false);
    }
  };

  const selectAreas = (
    <FormControl size="small" sx={{ width: '100%' }} error={dataError.areaIds}>
      <InputLabel id="selectAreas">Áreas</InputLabel>
      <Select
        labelId="selectAreas"
        size="small"
        value={reserva.areaIds}
        multiple
        label="Areas"
        onChange={(e) => {
          console.log(e);
          const data = {
            ...initData,
            ...reserva,
            areaIds: e.target.value,
          };
          setReserva(data);
        }}
        id={CONST.AREA_ID}
      >
        {areasStore.all.map((el) => {
          return (
            <MenuItem key={`areas-${el.id}`} value={el.id}>
              {el.name}
            </MenuItem>
          );
        })}
      </Select>
      {dataError.areaIds ? (
        <FormHelperText>{validFielddHelper(1)}</FormHelperText>
      ) : null}
    </FormControl>
  );

  const selectSubAreas = (
    <FormControl
      size="small"
      sx={{ width: '100%' }}
      error={dataError.subareaIds}
    >
      <InputLabel id="selectSubAreas">Sub áreas</InputLabel>
      <Select
        labelId="selectSubAreas"
        size="small"
        value={reserva.subareaIds}
        label="Sub areas"
        multiple
        onChange={(e) => {
          const data = {
            ...reserva,
            subareaIds: e.target.value,
          };
          setReserva(data);
        }}
        id={CONST.SUB_AREA_ID}
      >
        {subareaOptions.map((el) => {
          return (
            <MenuItem key={`subareas-${el.id}`} value={el.id}>
              {el.name}
            </MenuItem>
          );
        })}
      </Select>
      {dataError.subareaIds ? (
        <FormHelperText>{validFielddHelper(1)}</FormHelperText>
      ) : null}
    </FormControl>
  );

  const [isAllDias, setIsAllDias] = React.useState(false);
  const arrayDias = ['0', '1', '2', '3', '4', '5', '6'];
  const [selectGrupoDias, setLSelectGrupoDias] = React.useState([]);
  const setSGD = (dia) => {
    if (selectGrupoDias.includes(dia)) {
      const filter = selectGrupoDias.filter((e) => e !== dia);
      setLSelectGrupoDias(filter);
    } else {
      setLSelectGrupoDias((old) => [...old, dia]);
    }
  };

  React.useEffect(() => {
    if (selectGrupoDias.length === 7) {
      setIsAllDias(true);
    } else {
      setIsAllDias(false);
    }
  }, [selectGrupoDias]);

  function stylex(value) {
    return {
      backgroundColor: value ? AZUL_COLOR : BLANCO_COLOR,
      color: value ? BLANCO_COLOR : AZUL_COLOR,
      border: `1px solid ${AZUL_COLOR}`,
      '&:hover': {
        backgroundColor: value ? AZUL_COLOR : BLANCO_COLOR,
        border: `1px solid ${AZUL_COLOR}`,
      },
      '&:active': {
        backgroundColor: value ? AZUL_COLOR : BLANCO_COLOR,
      },
    };
  }

  const diasArray = ['DOM', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB'];
  const grupoDias = (
    <ButtonGroup size="small" aria-label="small button group">
      {diasArray.map((el, index) => (
        <Button
          key={index}
          variant={
            selectGrupoDias.includes(index.toString())
              ? 'contained'
              : 'outlined'
          }
          onClick={() => setSGD(index.toString())}
          sx={stylex(selectGrupoDias.includes(index.toString()))}
        >
          {el}
        </Button>
      ))}
    </ButtonGroup>
  );

  const [tab, setTab] = React.useState(1);
  const mesesArray = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  const selectMes = (
    <Grid item xs={12} md={12}>
      <FormControl size="small" sx={{ width: '100%' }}>
        <InputLabel id="selectMeses">Mes</InputLabel>
        <Select
          labelId="selectMeses"
          size="small"
          value={reserva.months}
          label="Meses"
          multiple
          onChange={(e) => {
            setReserva({ ...reserva, months: e.target.value });
          }}
          id={CONST.DATE_TO}
        >
          {mesesArray.map((el, index) => (
            <MenuItem key={index + 1} value={index}>
              {el}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );

  const botonFecha = (title, num) => (
    <Button
      key={`fechab-${num}`}
      variant={tab === num ? 'contained' : 'outlined'}
      sx={{
        backgroundColor: tab === num ? AZUL_COLOR : BLANCO_COLOR,
        color: tab === num ? BLANCO_COLOR : AZUL_COLOR,
        border: `1px solid ${AZUL_COLOR}`,

        '&:hover': {
          backgroundColor: tab === num ? AZUL_COLOR : BLANCO_COLOR,
          border: `1px solid ${AZUL_COLOR}`,
        },
        '&:active': {
          backgroundColor: tab === num ? AZUL_COLOR : BLANCO_COLOR,
        },
      }}
      onClick={() => {
        setTab(num);
        _handleSetDataFieldReserva(CONST.DATE_FROM, '');
        _handleSetDataFieldReserva(CONST.DATE_TO, null);
        setLSelectGrupoDias([]);
      }}
    >
      {title}
    </Button>
  );

  function getLastDayOfMonth(year, month) {
    let date = new Date(year, month + 1, 0);
    return date.getDate();
  }

  const todosDiasMes = () => {
    const anio = dateformat(null, { format: 'YYYY' });

    const diasFull = reserva.months.map((mes) => {
      const ultimoDia = getLastDayOfMonth(anio, mes);
      return selectGrupoDias.map((dia) => {
        var startDate = new Date(anio, mes, 1); // año(2023), mes(3), dia(1)
        var endDate = new Date(anio, mes, ultimoDia);
        var diasX = [];
        for (var d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
          if (d.getDay() === parseInt(dia)) {
            var dayYor = dateformat(`${d}`, {});
            if (d > Date.parse(dateformat(null, {}))) {
              diasX.push(dayYor);
            }
          }
        }
        return diasX;
      });
    }).flat();
    
    let data = [];
    diasFull.forEach((xx) => {
      xx.forEach((yy) => {
        if (isTDia) {
          data.push({
            [CONST.DESCRIPTION]: reserva.description,
            [CONST.ENTRY_TIME]: getDateTimezone(`${yy} 00:00:00`, timezone),
            [CONST.DEPARTURE_TIME]: getDateTimezone(`${yy} 23:59:59`, timezone),
          });
        } else {
          data.push({
            [CONST.DESCRIPTION]: reserva.description,
            [CONST.ENTRY_TIME]: getDateTimezone(
              `${yy} ${reserva.entryTime}:00`,
              timezone
            ),
            [CONST.DEPARTURE_TIME]: getDateTimezone(
              `${yy} ${reserva.departureTime}:00`,
              timezone
            ),
          });
        }
      });
    });
    _handleSetDataFieldReserva(CONST.DATE_FROM, diasFull[0][0]);
    return data;
  };

  const [isTDia, setIsTDia] = React.useState(false);
  // React.useEffect(() => {
  //   if (subareasStore.activo === null) return;
  //   const hoA = dateformat(areasStore.activo?.openingTime, {
  //     isHHmm: true,
  //     complete: true,
  //   });
  //   const hcA = dateformat(areasStore.activo?.closingTime, {
  //     isHHmm: true,
  //     complete: true,
  //   });
  //   const xe = dateformat(reserva.entryTime, {
  //     isHHmm: true,
  //     complete: true,
  //   });
  //   const xd = dateformat(reserva.departureTime, {
  //     isHHmm: true,
  //     complete: true,
  //   });
  //   setDataError({
  //     ...dataError,
  //     [CONST.ENTRY_TIME_ERROR]: xe < hoA || xe > hcA,
  //     [CONST.TIME_ERROR_IGUAl]: xe === xd,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [reserva.entryTime]);

  // React.useEffect(() => {
  //   if (subareasStore.activo === null) return;
  //   const hoA = dateformat(subareasStore.activo?.openingTime, {
  //     isHHmm: true,
  //     complete: true,
  //   });
  //   const hcA = dateformat(subareasStore.activo.closingTime, {
  //     isHHmm: true,
  //     complete: true,
  //   });
  //   const xe = dateformat(reserva.entryTime, {
  //     isHHmm: true,
  //     complete: true,
  //   });
  //   const xd = dateformat(reserva.departureTime, {
  //     isHHmm: true,
  //     complete: true,
  //   });
  //   setDataError({
  //     ...dataError,
  //     [CONST.DEPARTURE_TIME_ERROR]: xd < hoA || xd > hcA,
  //     [CONST.TIME_ERROR_IGUAl]: xe === xd,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [reserva.departureTime]);

  return (
    <Box component="form" sx={{ p: 1 }}>
      {/* <pre>{JSON.stringify(reserva, null, 2)}</pre> */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={activo ? 'Actualizando...' : 'Guardando...'}
          />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Nuevo bloqueo
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          {areasStore.loadingGetAll ? (
            <Box sx={{ width: '100%', mb: 1 }}>
              <LinearProgress />
            </Box>
          ) : null}
          {selectAreas}
        </Grid>
        <Grid item xs={12} md={12}>
          {loadingSubAreas ? (
            <Box sx={{ width: '100%', mb: 1 }}>
              <LinearProgress />
            </Box>
          ) : null}
          {selectSubAreas}
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            size="small"
            id={CONST.DESCRIPTION}
            required
            label="Motivo"
            value={reserva.description}
            onChange={(e) =>
              _handleSetDataFieldReserva(CONST.DESCRIPTION, e.target.value)
            }
            helperText={dataError.description ? validFielddHelper(1) : null}
            error={dataError.description}
          />
        </Grid>
        <Grid item xs={12} md={12} align="center">
          <ButtonGroup size="small" aria-label="small button group">
            {botonFecha('Día específico', 1)}
            {botonFecha('Por mes', 2)}
          </ButtonGroup>
        </Grid>
        {tab === 1 ? (
          <Grid item xs={12} md={12}>
            <FechaField
              l="Fecha"
              v={reserva.dateFrom}
              on={(e) => {
                _handleSetDataFieldReserva(CONST.DATE_FROM, dateformat(e, {}));
              }}
            />
          </Grid>
        ) : null}
        {tab === 2 ? (
          <>
            <Grid item xs={12} md={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isAllDias}
                    onChange={(e) => {
                      setIsAllDias(e.target.checked);
                      if (e.target.checked) {
                        setLSelectGrupoDias(arrayDias);
                      } else {
                        setLSelectGrupoDias([]);
                      }
                    }}
                  />
                }
                label="Todos los días"
              />
            </Grid>
            <Grid item xs={12} md={12} align="center">
              {grupoDias}
            </Grid>
            {selectMes}
          </>
        ) : null}

        {tab === 3 ? (
          <Grid item xs={12} md={12}>
            {selectMes}
          </Grid>
        ) : null}

          <>
            {dataError.entryTimeError ||
            dataError.departureTimeError ||
            dataError.timeErrorIgual ? (
              <>
                <AlertMsgComponent
                  alert={{
                    severity: 'error',
                    title: dataError.timeErrorIgual
                      ? 'La horas no pueden ser iguales'
                      : `La hora de ${
                          dataError.entryTimeError ? 'entrada' : 'sálida'
                        }  no se encuentra entre los parámetro del sub área`,
                  }}
                />
              </>
            ) : null}
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" sx={{ width: '100%' }}>
                <Typography variant="body">Hora de entrada</Typography>
                <TextField
                  size="small"
                  type="time"
                  value={reserva.entryTime}
                  onChange={(e) => {
                    _handleSetDataFieldReserva(
                      CONST.ENTRY_TIME,
                      e.target.value
                    );
                  }}
                  id={CONST.ENTRY_TIME}
                  helperText={
                    dataError.entryTime
                      ? validFielddHelper(1)
                      : null
                        // `min: ${dateformat(areasStore.activo?.openingTime, {
                        //   format: 'hh:mm a',
                        //   complete: true,
                        // })}`
                  }
                  error={
                    dataError.entryTime ||
                    dataError.entryTimeError ||
                    dataError.timeError
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" sx={{ width: '100%' }}>
                <Typography variant="body">Hora de salida</Typography>
                <TextField
                  size="small"
                  type="time"
                  value={reserva.departureTime}
                  onChange={(e) => {
                    _handleSetDataFieldReserva(
                      CONST.DEPARTURE_TIME,
                      e.target.value
                    );
                  }}
                  helperText={
                    dataError.departureTime
                      ? validFielddHelper(1)
                      : null
                      // `max: ${dateformat(areasStore.activo?.closingTime, {
                      //     format: 'hh:mm a',
                      //     complete: true,
                      //   })}`
                  }
                  error={
                    dataError.departureTime ||
                    dataError.departureTimeError ||
                    dataError.timeError
                  }
                />
              </FormControl>
            </Grid>
          </>
      {/*!isTDia && subareasStore.activo ? (
      ) : null*/}
        <Grid item md={12} align="center">
          <BotonNuevoComponent 
            click={() => setModalConfirm(true)} 
            text="GUARDAR" 
            morado 
          />
        </Grid>
      </Grid>
      
      <Modal
          id="modalToggleActivo"
          title="Bloquear subareas"
          open={modalConfirm}
          maxWidth="sm"
          onClose={() => setModalConfirm(false)}
        >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Si existen reservaciones, eventos o bloqueos en las subáreas seleccionadas, estas se eliminarán y se bloquearán las horas.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            onClick={async () => {
              setModalConfirm(false);
              await handleSubmit();
            }}
          >
            ACEPTAR
          </Button>
          <Button
            sx={{ color: MORADO_COLOR }}
            onClick={() => setModalConfirm(false)}
          >
            CANCELAR
          </Button>
        </DialogActions>
      </Modal>
    </Box>
  );
};

export default FormReservasEspeciales;
