import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AodIcon from '@mui/icons-material/Aod';
import BusinessIcon from '@mui/icons-material/Business';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import CastConnectedIcon from '@mui/icons-material/CastConnected';
import CategoryIcon from '@mui/icons-material/Category';
import ChairIcon from '@mui/icons-material/Chair';
import ClassIcon from '@mui/icons-material/Class';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ContactsIcon from '@mui/icons-material/Contacts';
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EventIcon from '@mui/icons-material/Event';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import GridViewIcon from '@mui/icons-material/GridView';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import HouseIcon from '@mui/icons-material/House';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MoreIcon from '@mui/icons-material/More';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import OnDeviceTrainingIcon from '@mui/icons-material/OnDeviceTraining';
import PaymentsIcon from '@mui/icons-material/Payments';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SensorDoorIcon from '@mui/icons-material/SensorDoor';
import SettingsIcon from '@mui/icons-material/Settings';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { FluentMdl2AllApps } from '../static/images/FluentMdl2AllApps';

export const login = {
  title: 'Inicio de sesion',
  icon: 'fa fa-user',
  to: '/login',
};
export const register = {
  title: 'Registro',
  icon: 'fa fa-user',
  to: '/register',
};
export const recover = {
  title: 'RecuperarContraseña',
  icon: '',
  to: '/recoverPassword',
};

export const userDeletionRequest = {
  title: 'Solicitar eliminación de usuario',
  icon: '',
  to: '/userDeletionRequests',
};

export const correoVerificado = {
  title: 'Correo verificado',
  icon: '',
  to: '/correoVerificado',
};

export const tokenExpirado = {
  title: 'Token expirado',
  icon: '',
  to: '/tokenExpirado',
};

export const updatePassword = {
  title: 'Actualizar contraseña',
  icon: '',
  to: '/updatePassword',
};

export const panelControl = {
  title: 'Panel de Control',
  icon: <DashboardCustomizeRoundedIcon />,
  to: '/panelControl',
};
export const todos = {
  title: 'Todos',
  icon: (
    <FluentMdl2AllApps width={20} heigth={20} style={{ marginLeft: '.2em' }} />
  ),
  to: '/todos',
};
export const users = {
  title: 'Usuarios (Todos)',
  icon: <PeopleAltIcon />,
  to: `${todos.to}/users`,
};

export const notificaciones = {
  title: 'Notificaciones',
  icon: <NotificationsNoneIcon />,
  to: `${todos.to}/notificaciones`,
};

export const crm = {
  title: 'CRM',
  icon: <GridViewIcon />,
  to: '/crm',
};
export const tickets = {
  title: 'Tickets',
  icon: <MoreIcon />,
  to: `${crm.to}/tickets`,
};
export const usersDelete = {
  title: 'Solicitudes de eliminación',
  icon: <PersonOffIcon />,
  to: `${crm.to}/deletion_requests`,
};
export const entidades = {
  title: 'Entidades',
  icon: <BusinessIcon />,
  to: `${todos.to}/entidades`,
};
export const entidadesInfo = {
  title: 'Información de la entidad',
  icon: <BusinessIcon />,
  to: `/entidades/info`,
};

export const banks = {
  title: 'Bancos (Todos)',
  icon: <AccountBalanceIcon />,
  to: `${todos.to}/banks`,
};
export const bancos = {
  title: 'Bancos',
  icon: <AccountBalanceIcon />,
  to: '/bancos',
};

export const dashboard = {
  title: 'Dashboard',
  icon: <CategoryIcon />,
  to: `/dashboard`,
};

export const areas = {
  title: 'Áreas',
  icon: <SportsTennisIcon />,
  to: `/areas`,
};
export const areasInfo = {
  title: 'Información del área',
  icon: <SportsTennisIcon />,
  to: `/areas/info`,
};

export const subareas = {
  title: 'Sub áreas',
  icon: <SportsBaseballIcon />,
  to: `/subareas`,
};
export const subAreasInfo = {
  title: 'Información del Sub Área',
  icon: <SportsTennisIcon />,
  to: `/subareas/info`,
};

export const reservas = {
  title: 'Reservaciones',
  icon: <ChairIcon />,
  to: `/reservaciones`,
};

export const reservasEspeciales = {
  title: 'Bloqueos de reservas',
  icon: <ConfirmationNumberIcon />,
  to: `/bloqueos-reservas`,
};

export const panelReservas = {
  title: 'Panel de reservas',
  icon: <ChairIcon />,
  to: `/panel/reservas`,
};

export const residencias = {
  title: 'Residencias',
  icon: <HouseIcon />,
  to: `/residencias`,
};

export const codigos = {
  title: 'Codigos',
  icon: <HouseIcon />,
  to: `/acciones`,
};

export const acciones = {
  title: 'Acciones',
  icon: <CardMembershipIcon />,
  to: `/acciones`,
};

export const codigosInfo = {
  title: 'Información del código',
  icon: <HouseIcon />,
  to: `/codigos/info`,
};

export const usuarios = {
  title: 'Usuarios',
  icon: <GroupAddIcon />,
  to: `/usuarios`,
};
export const usuariosInfo = {
  title: 'Información del usuario',
  icon: <GroupAddIcon />,
  to: `/usuarios/info`,
};

export const invitados = {
  title: 'Invitados',
  icon: <PeopleOutlineIcon />,
  to: `/invitados`,
};

export const delivery = {
  title: 'Delivery',
  icon: <DeliveryDiningIcon />,
  to: `/delivery`,
};

export const ajustes = {
  title: 'Ajustes',
  icon: <SettingsIcon />,
  to: `/ajustes`,
};

export const appModules = {
  title: 'Módulos (Todos)',
  icon: <ViewModuleIcon />,
  to: `${todos.to}/app-modules`,
};

export const modulos = {
  title: 'Módulos',
  icon: <ViewModuleIcon />,
  to: `/modulos`,
};

export const recibos = {
  title: 'Recibos',
  icon: <ReceiptIcon />,
  to: `/recibos`,
};

export const tipos = {
  title: 'Tipos de datos',
  icon: <ContactsIcon />,
  to: `/tipos`,
};

export const eventos = {
  title: 'Eventos',
  icon: <EventIcon />,
  to: `/eventos`,
};
export const tiposEventos = {
  title: 'Tipos de eventos',
  icon: <EventIcon />,
  to: `${tipos.to}/tipos_eventos`,
};

export const documentos = {
  title: 'Documentos',
  icon: <FilePresentIcon />,
  to: `/documentos`,
};
export const tiposDocumentos = {
  title: 'Tipos de documentos',
  icon: <FilePresentIcon />,
  to: `${tipos.to}/documentos`,
};

export const descuentos = {
  title: 'Descuentos',
  icon: <LocalOfferIcon />,
  to: `/descuentos`,
};
export const tiposDscuentos = {
  title: 'Tipos de descuentos',
  icon: <LocalOfferIcon />,
  to: `${tipos.to}/descuentos`,
};

export const puertas = {
  title: 'Accesos',
  icon: <SensorDoorIcon />,
  to: `/accesos`,
};

export const contactos = {
  title: 'Contactos',
  icon: <ContactsIcon />,
  to: `/contactos`,
};

export const tiposContactos = {
  title: 'Tipos de contactos',
  icon: <ContactsIcon />,
  to: `${tipos.to}/contactos`,
};
export const tiposInvitacion = {
  title: 'Tipos de invitación',
  icon: <Diversity3Icon />,
  to: `${tipos.to}/invitacion`,
};

export const perfil = {
  title: 'Mi Perfil',
  icon: <ContactsIcon />,
  to: `/mi-perfil`,
};

export const iots = {
  title: 'Iots',
  icon: <OnDeviceTrainingIcon />,
  to: `/iot`,
};

export const iotsQr = {
  title: 'Lector QR',
  icon: <AodIcon />,
  to: `${iots.to}/code-qr`,
};

export const iotTuneles = {
  title: 'Tuneles',
  icon: <CastConnectedIcon />,
  to: `${iots.to}/tuneles`,
};

export const accesos = {
  title: 'Apertura de puertas',
  icon: <SyncAltIcon />,
  to: `/entradas-salidas`,
};

export const settings = {
  title: 'Configuración',
  icon: <SettingsIcon />,
  to: `/settings`,
};

export const tiposClases = {
  title: 'Tipos de clases',
  icon: <ClassIcon />,
  to: `/tipos_clases`,
};

export const metodosPago = {
  title: 'Métodos de pago',
  icon: <PaymentsIcon />,
  to: `/metodos_pago`,
};

export const clases = {
  title: 'Clases',
  icon: <ClassIcon />,
  to: `/clases`,
};

export const panelClases = {
  title: 'Panel de clase',
  icon: <ClassIcon />,
  to: `${clases.to}/panel`,
};

export const docentes = {
  title: 'Docentes',
  icon: <ClassIcon />,
  to: `/docentes`,
};

export const referidos = {
  title: 'Referidos',
  icon: <ClassIcon />,
  to: `/referidos`,
};

export const restaurantes = {
  title: 'Restaurantes',
  icon: <RestaurantIcon />,
  to: `/restaurantes`,
};

export const panelRestaurante = {
  title: 'Panel del restaurante',
  icon: <RestaurantIcon />,
  to: `/restaurante/panel`,
};

export const tiposRestaurante = {
  title: 'Tipos de restaurante',
  icon: <RestaurantIcon />,
  to: `/tipos_restaurante`,
};

export const tiposServicios = {
  title: 'Tipos de servicios',
  icon: <CleaningServicesIcon />,
  to: `/tipos_servicios`,
};

export const servicios = {
  title: 'Servicios',
  icon: <CleaningServicesIcon />,
  to: `/servicios`,
};

export const panelServicios = {
  title: 'Panel de servicio',
  icon: <CleaningServicesIcon />,
  to: `${servicios.to}/panel`,
};
